import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { Fragment } from "react";
import { styles } from "./styles";
import { currencyFormatter } from "../../../../../../utils/currencyFormatter";
import { ExpressHeadPDF } from "./ExpressHeadPDF";
import { dateFormatter } from "../../../../../../utils/dateFormatter";

export const ProductsPDF = ({ products, dates }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} orientation="portrait" wrap={true}>
                <ExpressHeadPDF />

                <View style={{ flexDirection: "column", justifyContent: "flex-end", width: "100%", gap: 1 }} fixed>
                    <Text style={{ textAlign: "right", fontSize: 8, alignItems: "center" }}>
                        <View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
                            {dates.map((date, index) => (
                                <Text key={index} style={{ textAlign: "right", fontSize: 8 }}>
                                    {date}{index < dates.length - 1 ? ", " : ""}
                                </Text>
                            ))}
                        </View>
                    </Text>
                </View>


                {products.map((product) => (
                    <Fragment key={product.id}>
                        <View style={{ width: "100%", flexDirection: "row", mt: 2 }}>
                            <View
                                style={[
                                    styles.tbody,
                                    { alignItems: "center", justifyContent: "center", width: "50%" },
                                ]}
                            >
                                <Image
                                    src={`https://democrata-public-files.s3.amazonaws.com/140x80/${product.referencia
                                        }.png?${new Date().getTime()}`}
                                    alt={`${product.referencia}-img`}
                                    cache={true}

                                    key={`${product.referencia}-${product.semana}`}
                                    style={{ width: 60, height: 60, objectFit: "contain" }}
                                />

                                <Text>
                                    {product.linha} - {product.cor}
                                </Text>

                                <Text>
                                    {product.referencia} - {dateFormatter(product.semana)}
                                </Text>
                            </View>

                            <View style={[
                                styles.tbody,
                                styles.tbody2,
                                { flexDirection: "column", gap: 1 },
                            ]}>
                                <View
                                    style={[
                                        styles.tbody2,
                                        { flexDirection: "row", gap: 1, justifyContent: "flex-start", height: "100%", alignItems: "center", alignSelf: "baseline" },
                                    ]}
                                >
                                    {product.gradeCompleta && product.gradeCompleta?.map((grade) => (
                                        <View
                                            style={{
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                width: "10%",
                                                gap: 1,
                                            }}
                                        >
                                            <Text style={styles.gradeItemHeader}>{grade.nro}</Text>
                                            <Text style={styles.gradeItemContent}>{grade.estoque}</Text>
                                        </View>
                                    ))}
                                </View>

                                <View style={{ flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", gap: 4, marginLeft: 2 }}>
                                    <Text style={styles.price}>Preço de Venda: {currencyFormatter(product.precoVendedor)}</Text>
                                    <Text style={styles.price}>Preço Recomendado: {currencyFormatter(product.precoConsumidor)}</Text>
                                </View>
                            </View>
                        </View>
                    </Fragment>
                ))}
            </Page>

        </Document>
    );
};
