import {
  Box,
  Button,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Image from "mui-image";

import { blue, cyan, grey, orange, red } from "@mui/material/colors";
import {
  Close,
  Edit,
  Favorite,
  FavoriteBorderOutlined,
  Person2,
  Sell,
} from "@mui/icons-material";
import { DialogConfirmation } from "../../DialogConfirmation";
import { useState } from "react";

import { Label } from "@mui/icons-material";
import { useFavorites } from "../../../hooks/useFavorites";
import { ActionButton } from "./ActionButton";
import { currencyFormatter } from "../../../utils/currencyFormatter";
import { Grade } from "../Grade";
import { useSelectedProductsContext } from "../../../contexts/SelectedProductsContext";
import {
  BoxActionGrade, BoxContainerAction, BoxContainerContent, BoxContainerGrade, BoxContainerImage, BoxContainerPrice,
  BoxContainerTitle, BoxContent, BoxContentPrice, BoxContentTitle, BoxDialog,
  BoxImage, BoxPriceRec, BoxPriceSug, BoxTotal, Card, CheckCircleIconMui, GridGrade,
  IconButtonCheck, Img, PaperDialog, StyledImage, TextDate, TextDialogRef,
  TextDialogTitle, TextPriceFinal, TextPriceRec, TextPriceSug, TextReference, TextTitle, TextTotal
} from "./styles";

export const ProductCardDefault = ({
  children,
  variant,
  product,
  activeHover,
  grade = [],
  actions,
  favorite,
  finalPrice,
  disableApplyGradeButton,
  totalQuant,
  disableImageCache = false,
  disableLight = false,
  customRemoveRender,
  cart
}) => {
  const { isFavorite, toggleFavorite } = useFavorites(product, favorite);
  const { isSelected, toggleSelection } = useSelectedProductsContext();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isBorderActive = () => {
    if (!isSelected(product.id) || disableLight || variant === "extended") {
      return "transparent";
    }

    return isSelected(product.id) && "success.light";
  };

  return (
    <>
      <Card
        sx={{
          borderColor: isBorderActive(),
          cursor: activeHover && "pointer",
        }}
        onClick={() => toggleSelection(product)}
      >
        {/* Container 1 */}
        <BoxContainerContent>
          <Tooltip title="Clique para abrir a imagem" placement="top" arrow>
            <BoxContainerImage>
              {disableImageCache && (
                <BoxImage
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenDialog();
                  }}
                >
                  <Img
                    src={`https://democrata-public-files.s3.amazonaws.com/thumbs/${product.referencia
                      }.png?${new Date().getTime()}`}
                    crossOrigin="anonymous"
                  />
                </BoxImage>
              )}
              {!disableImageCache && (
                <StyledImage
                  src={`https://democrata-public-files.s3.amazonaws.com/${product.referencia}.png`}
                  alt={product.titulo}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenDialog();
                  }}
                  onError={(e) =>
                    (e.target.src = "../assets/image-error-placeholder.png")
                  }
                />
              )}
            </BoxContainerImage>
          </Tooltip>
          <BoxContent>
            <BoxContainerTitle
              flexDirection={!isMobile && variant === "extended" ? "column" : "row"}
            >
              <BoxContentTitle>
                <BoxContentTitle flexWrap="wrap">
                  <Tooltip
                    arrow
                    title={<Box>{product.descricaoRegra || `${product.linha} ${product.cor}`}</Box>}
                  >
                    <TextTitle>
                      {product.linha} {product.cor}
                    </TextTitle>
                  </Tooltip>
                  <TextReference isSmallScreen={isSmallScreen}>
                    {product.referencia}
                  </TextReference>
                </BoxContentTitle>
                {customRemoveRender && customRemoveRender}
              </BoxContentTitle>
            </BoxContainerTitle>

            {!cart && (
              <BoxContainerPrice>
                <Box>
                  <BoxContentPrice>
                    {!finalPrice && (
                      <Tooltip title="Preço de Venda" arrow>
                        <BoxPriceSug>
                          <Person2 sx={{ mr: 1, color: blue[800], fontSize: "20px" }} />
                          <TextPriceSug>
                            {currencyFormatter(
                              product.precoRepresentante?.preco ||
                              product.precoVendedor ||
                              0
                            )}
                          </TextPriceSug>
                        </BoxPriceSug>
                      </Tooltip>
                    )}

                    {finalPrice && (
                      <>
                        <TextPriceFinal>
                          {currencyFormatter(product.unitario || 0)}
                        </TextPriceFinal>
                      </>
                    )}

                    {product.precoConsumidor && (
                      <Tooltip title="Preço Recomendado" arrow>
                        <BoxPriceRec>
                          <Sell sx={{ mr: 1, color: cyan[900], fontSize: "20px" }} />
                          <TextPriceRec>
                            {currencyFormatter(
                              product.precoConsumidor?.preco ||
                              product.precoConsumidor ||
                              0
                            )}
                          </TextPriceRec>
                        </BoxPriceRec>
                      </Tooltip>
                    )}
                  </BoxContentPrice>
                </Box>

                {variant !== "extended" && (
                  <TextDate>
                    {(product.semana &&
                      new Date(product.semana).toLocaleDateString("pt-BR", {
                        timeZone: "UTC",
                      })) ||
                      product.entrega}
                  </TextDate>
                )}
              </BoxContainerPrice>
            )}
            <BoxContainerGrade>
              <GridGrade>
                {grade.map((item) => (
                  <Grade
                    key={item.nro}
                    nro={item.nro}
                    estoque={item.estoque}
                    alert={item.alertStock}
                  />
                ))}
                {grade.length > 0 && (
                  <BoxActionGrade>
                    <ActionButton
                      action={actions?.remove}
                      title="Remover grade"
                      color={red[700]}
                      IconComponent={Close}
                    />
                    <ActionButton
                      action={actions?.edit}
                      title="Editar grade"
                      color={orange[700]}
                      IconComponent={Edit}
                    />
                  </BoxActionGrade>
                )}

                {!disableApplyGradeButton && grade && grade.length === 0 && (
                  <Button
                    variant="text"
                    className="text-muted"
                    size="small"
                    disabled={disableApplyGradeButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      actions.handleAddGrade(product);
                    }}
                  >
                    Clique aqui para aplicar grade
                  </Button>
                )}
              </GridGrade>
            </BoxContainerGrade>

            <BoxTotal>
              <TextTotal>
                Total: {totalQuant || product.quantidade}
              </TextTotal>

              <Box>
                {favorite ? (
                  <>
                    {isSelected(product.id) && (
                      <IconButtonCheck>
                        <CheckCircleIconMui sx={{ color: "success.light" }} />
                      </IconButtonCheck>
                    )}
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleFavorite();
                      }}
                    >
                      {isFavorite ? (
                        <Favorite sx={{ color: red[800] }} />
                      ) : (
                        <FavoriteBorderOutlined
                          color="error"
                          sx={{ color: grey[800] }}
                        />
                      )}
                    </IconButton>
                  </>
                ) : null}
              </Box>
            </BoxTotal>
          </BoxContent>
        </BoxContainerContent>
        {/* Container 2 */}
        {variant === "extended" && (
          <BoxContainerAction>
            {children}
          </BoxContainerAction>
        )}
      </Card>

      <DialogConfirmation
        open={isDialogOpen}
        onClose={handleCloseDialog}
        closeButton
        imageDialog
      >
        <BoxDialog>
          <TextDialogTitle>
            {product.descricaoRegra || `${product.conceito} ${product.linha} ${product.cor}`}
          </TextDialogTitle>

          <TextDialogRef>
            <Label sx={{ color: "orange" }} fontSize="12" />{" "}
            {product.referencia}
          </TextDialogRef>

          <PaperDialog>
            <Image
              src={`https://democrata-public-files.s3.amazonaws.com/${product.referencia}.png`}
              alt={product.titulo}
              loading="lazy"
              fit="contain"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "path/to/fallback-image.png";
              }}
            />
          </PaperDialog>
        </BoxDialog>
      </DialogConfirmation>
    </>
  );
};
