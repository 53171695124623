export const productWithReservado = (product, reservedAmount) => {
  let start, end;

  if (product.genero === "F") {
    start = 1;
    end = 8;
  } else if (product.genero === "M") {
    start = 5;
    end = 13;
  } else {
    start = 5;
    end = 13;
  }

  if (!reservedAmount || reservedAmount.length === 0) {
    return Array.from({ length: end - start + 1 }, (_, i) => i + start).reduce(
      (acc, i) => {
        const nro = product.grade[`nro${i}`];
        if (nro) {
          acc.push({ nro, estoque: 0 });
        }
        return acc;
      },
      []
    );
  }

  const reserved = reservedAmount[0];
  const reservado = Array.from(
    { length: end - start + 1 },
    (_, i) => i + start
  ).reduce((acc, i) => {
    const nro = product.grade[`nro${i}`];
    const estoque = reserved[`quant${i}`];

    if (nro && nro !== null && estoque !== null) {
      acc.push({ nro, estoque });
    }

    return acc;
  }, []);

  return reservado || [];
};

export const productWithGradeCheckout = (
  products,
  date,
  disableZeroAndNull
) => {
  return products.map((product) => {
    let start, end;

    if (product.genero === "F") {
      start = 1;
      end = 8;
    } else if (product.genero === "M") {
      start = 5;
      end = 13;
    } else {
      start = 5;
      end = 13;
    }

    const gradeProduto = Array.from(
      { length: end - start + 1 },
      (_, i) => i + start
    ).reduce((acc, i) => {
      const nro = product.grade[`nro${i}`];
      const estoque = product[`quant${i}`] < 0 ? 0 : product[`quant${i}`];

      if (disableZeroAndNull && estoque <= 0) {
        return acc;
      }

      acc.push({ nro, estoque });
      return acc;
    }, []);

    return {
      ...product,
      gradeProduto,
      data: date,
    };
  });
};

export const productWithGrade = (products, date, disableZeroAndNull) => {
  return products.reduce((acc, product) => {
    const gradeProduto = new Set();
    let start, end;

    if (product.genero === "F") {
      start = 1;
      end = 8;
    } else {
      if (product.tipoProduto === "CINTO") {
        start = 3;
        end = 11;
      } else if (product.tipoProduto === "MOCHILA") {
        start = 1;
        end = 1;
      } else {
        start = 5;
        end = 13;
      }
    }

    Array.from({ length: end - start + 1 }, (_, i) => i + start).forEach(
      (i) => {
        const nro = product.grade[`nro${i}`];
        const estoque = Math.max(0, product[`quant${i}`]);

        if (disableZeroAndNull && estoque <= 0) {
          return;
        }

        const observableNumbersByGenre = {
          M: ["37", "44", "45", "80", "85", "120"],
        };

        const restrictedNumbers = observableNumbersByGenre[product.genero];
        if (
          restrictedNumbers &&
          restrictedNumbers.includes(nro) &&
          estoque < 5
        ) {
          return;
        }

        gradeProduto.add({ nro, estoque });
      }
    );

    acc.push({
      ...product,
      gradeProduto: Array.from(gradeProduto),
      data: date,
    });

    return acc;
  }, []);
};

/**
 * @function extractGradePadrao
 * @description Essa função é utilizada para extrair a grade padrão selecionada, e atribuir ao objecto do produto.
 * a função também é responsável por verificar a quantidade em estoque.
 * @param {Array} grades - Array da grade padrão. Exemplo: quant1, quant2, quant3, e as numeracoes.
 * @param {Array} stock - Array do estoque. Exemplo: quant1, quant2, quant3, e as numeracoes.
 * @returns {Array} - Retorna um array com a grade padrão formatada e atribuida.
 * Exemplo: { nro: "38", estoque: 188 } - O estoque na gradePadrão seria o valor pedido.
 */
export const extractGradePadrao = (grades, stock) => {
  return (
    Array.isArray(grades) &&
    grades.flatMap((grade) => {
      if (!grade || !grade.grade) return [];

      return Object.entries(grade.grade)
        .filter(
          ([key, sizeValue]) => key.startsWith("nro") && sizeValue !== null
        )
        .map(([key, sizeValue]) => {
          const sizeInRange =
            (sizeValue >= 37 && sizeValue <= 45) ||
            (sizeValue >= 80 && sizeValue <= 120) ||
            (sizeValue === 1 && grade.tipoProduto === "MOCHILA");
          if (!sizeInRange) return null;

          const quantKey = `quant${key.match(/\d+/)[0]}`;
          const gradeQuantValue = grade[quantKey];

          const availableStock = stock.find(
            (stockItem) => stockItem.nro === sizeValue
          );

          const currentTemporaryOrder = availableStock
            ? availableStock.estoque
            : 0;

          const stockCurrentValue = availableStock
            ? availableStock.estoque - (currentTemporaryOrder?.estoque || 0)
            : 0;

          if (stockCurrentValue < gradeQuantValue) {
            return {
              nro: sizeValue,
              estoque: stockCurrentValue,
              alertStock: { nro: sizeValue, alert: true },
            };
          }

          return { nro: sizeValue, estoque: gradeQuantValue };
        })
        .filter((item) => item !== null);
    })
  );
};

export const extractGradePadraoWithReserved = (
  grades,
  stock,
  productTemporaryOrder
) => {
  return (
    Array.isArray(grades) &&
    grades.flatMap((grade) => {
      if (!grade || !grade.grade) return [];

      return Object.entries(grade.grade)
        .filter(
          ([key, sizeValue]) => key.startsWith("nro") && sizeValue !== null
        )
        .map(([key, sizeValue]) => {
          const sizeInRange = grade.genero === "F"
            ? (sizeValue >= 33 && sizeValue <= 40)
            : (sizeValue >= 37 && sizeValue <= 45) ||
            (sizeValue >= 80 && sizeValue <= 120) ||
            (sizeValue === 1 && grade.tipoProduto === "MOCHILA");
          if (!sizeInRange) return null;

          const quantKey = `quant${key.match(/\d+/)[0]}`;
          const gradeQuantValue = grade[quantKey] || null;

          const availableStock = stock.find(
            (stockItem) => stockItem.nro === sizeValue
          );

          const currentTemporaryOrder = productTemporaryOrder.find(
            (orderItem) => orderItem.nro === sizeValue
          );

          const stockCurrentValue = availableStock
            ? availableStock.estoque - (currentTemporaryOrder?.estoque || 0)
            : null;

          if (stockCurrentValue < gradeQuantValue) {
            return {
              nro: sizeValue,
              estoque: stockCurrentValue,
              alertStock: { nro: sizeValue, alert: true },
            };
          }

          return { nro: sizeValue, estoque: gradeQuantValue };
        })
        .filter((item) => item !== null);
    })
  );
};

export const extractGrade = (grades) => {
  return (
    Array.isArray(grades) &&
    grades.flatMap((grade) => {
      if (!grade || !grade.grade) return [];

      return Object.entries(grade.grade)
        .filter(
          ([key, sizeValue]) => key.startsWith("nro") && sizeValue !== null
        )
        .map(([key, sizeValue]) => {
          const sizeInRange = grade.genero === "F"
            ? (sizeValue >= 33 && sizeValue <= 40)
            : (sizeValue >= 37 && sizeValue <= 45) ||
            (sizeValue >= 80 && sizeValue <= 120) ||
            (sizeValue === 1 && grade.tipoProduto === "MOCHILA");
          if (!sizeInRange) return null;

          const quantKey = `quant${key.match(/\d+/)[0]}`;
          const gradeQuantValue = grade[quantKey];
          const estoque = gradeQuantValue !== null ? gradeQuantValue : 0;

          return { nro: sizeValue, estoque };
        })
        .filter((item) => item !== null);
    })
  );
};

/**
 * @function extractGradePadrao
 * @description Essa função tem a mesma funcionalidade da função extractGradePadrao,
 *  porém não utiliza o map das numeracoes.
 * @param {Array} grades - Array da grade padrão. Exemplo: quant1, quant2, quant3, e as numeracoes.
 * @param {Array} stock - Array do estoque. Exemplo: quant1, quant2, quant3, e as numeracoes.
 * @returns {Array} - Retorna um array com a grade padrão formatada e atribuida.
 * Exemplo: { nro: "38", estoque: 188 } - O estoque na gradePadrão seria o valor pedido.
 */
export const extractGradePadraoWithoutMap = (
  grades,
  productStock,
  productTemporaryOrder
) => {
  if (!Array.isArray(grades)) return [];

  return grades.reduce((acc, grade) => {
    if (!grade || !grade.nro) return acc;

    const sizeValue = grade.nro;
    const sizeInRange =
      (sizeValue >= 37 && sizeValue <= 45) ||
      (sizeValue >= 80 && sizeValue <= 120) ||
      (sizeValue === 1 && grade.tipoProduto === "MOCHILA");
    if (!sizeInRange) return acc;

    const gradeQuantValue = grade.estoque || null;

    const availableStock = productStock.find(
      (stockItem) => stockItem.nro === sizeValue
    );
    const currentTemporaryOrder = productTemporaryOrder.find(
      (orderItem) => orderItem.nro === sizeValue
    );

    const stockCurrentValue = availableStock
      ? availableStock.estoque - (currentTemporaryOrder?.estoque || null)
      : null;

    if (stockCurrentValue < gradeQuantValue) {
      acc.push({
        nro: sizeValue,
        estoque: stockCurrentValue,
        alertStock: { nro: sizeValue, alert: true },
      });
    } else {
      acc.push({ nro: sizeValue, estoque: gradeQuantValue });
    }

    return acc;
  }, []);
};

export const productObjectPDF = (products, disableZeroAndNull) => {
  return products.reduce((acc, product) => {
    const gradeProduto = new Set();
    let start, end;

    if (product.genero === "F") {
      start = 1;
      end = 8;
    } else {
      if (product.tipoProduto === "CINTO") {
        start = 3;
        end = 11;
      } else if (product.tipoProduto === "MOCHILA") {
        start = 1;
        end = 1;
      } else {
        start = 5;
        end = 13;
      }
    }

    Array.from({ length: end - start + 1 }, (_, i) => i + start).forEach(
      (i) => {
        const nro = product.grade[`nro${i}`];
        const estoque = Math.max(0, product[`quant${i}`]);

        if (disableZeroAndNull && estoque <= 0) {
          return;
        }

        gradeProduto.add({ nro, estoque });
      }
    );

    acc.push({
      ...product,
      gradeProduto: Array.from(gradeProduto),
    });

    return acc;
  }, []);
};
