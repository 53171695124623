import { isMultipleOfBaseMultiples } from "./isMultipleOfBaseMultiples";

export default function transformProductJsonToCheckout(
  products,
  { cliente },
  representant,
  tabelaPreco
) {
  const productsArray = Array.isArray(products)
    ? products
    : Array.from(products);

  return productsArray.map((product) => {
    const quants = {};

    product.gradePadrao.forEach((gradeItem) => {
      const gradeKey = Object.keys(product.grade).find(
        (key) => product.grade[key] === gradeItem.nro.toString()
      );
      if (gradeKey) {
        const quantNumber = gradeKey.replace("nro", "");
        quants[`quant${quantNumber}`] = gradeItem.estoque;
      }
    });

    const totalQuant = Object.values(quants).reduce(
      (acc, curr) => acc + curr,
      0
    );

    const validateQuants = isMultipleOfBaseMultiples(totalQuant);

    if (!validateQuants && product.linha !== "MOCHILA") {
      throw new Error(
        `A quantidade total da grade do produto ${product.referencia} deve ser múltipla de 8, 10, 12, 15 ou 18.`
      );
    }

    const newProduct = {
      cliente: cliente.cnpjCpf,
      conceito: product.conceito,
      cor: product.cor,
      genero: product.genero,
      entrega: new Date(product.semana).toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      }),
      grade: { grade: product.grade.grade },
      id: product.id,
      linha: product.linha,
      preco: product.precoRepresentante.preco,
      unitario: product.precoRepresentante.preco,
      referencia: product.referencia,
      reprVendedor: representant,
      ...quants,
      tabelaPreco,
      quantidade: totalQuant,
    };

    return newProduct;
  });
}
