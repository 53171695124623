import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  IconButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useTabelasPrecoContext } from "../contexts/TabelasPreco";
import { Settings } from "@mui/icons-material";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { blue, grey, orange } from "@mui/material/colors";
import { toast } from "react-toastify";
import { useSelectedProductsContext } from "../contexts/SelectedProductsContext";

export const ConfigTabela = ({ handleDrawerClose, handleOpenModal }) => {
  const isMobile = useBreakpoints("md");

  return (
    <>
      {!isMobile && (
        <Tooltip title="Configurações de Tabela">
          <IconButton
            variant="text"
            sx={{
              color: "#FFF",
              "&:hover": {
                color: orange[300],
              },
            }}
            onClick={handleOpenModal}
          >
            <Settings />
          </IconButton>
        </Tooltip>
      )}

      {isMobile && (
        <ListItem
          onClick={() => {
            handleOpenModal();
            handleDrawerClose();
          }}
          sx={{ color: !isMobile && grey[100], my: 1 }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Tabelas de Preço" />
        </ListItem>
      )}
    </>
  );
};
