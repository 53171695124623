import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { Fragment } from "react";
import { ProductCardDefault } from "../../../../../components/GradeExpress/Standard/ProductCardDefault";
import { useTabelasPrecoContext } from "../../../../../contexts/TabelasPreco";
import { useProductCardActions } from "../../../../../hooks/useProductCardActions";
import { useProductsQuery } from "../../../../../hooks/useInfinityQuery";
import { useBreakpoints } from "../../../../../hooks/useBreakpoints";
import { useProductContext } from "../../../../../contexts/ProductContext";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { useLoggedUser } from "../../../../../contexts/LoggedUserContext";

export const ExpressProductList = ({ expressDates, filter }) => {
  const { loggedUser } = useLoggedUser();

  const { selectedTabelaPreco } = useTabelasPrecoContext();

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const { handleHover, handleHoverLeave, hoveredProductId } =
    useProductCardActions();

  const { handleSelectProducts } = useProductContext();

  const debouncedSearch = useDebounce(filter, 1000);

  const { data, error, fetchNextPage, hasNextPage, status } = useProductsQuery(
    expressDates,
    selectedTabelaPreco,
    debouncedSearch,
    loggedUser
  );

  const dataLength = data?.pages?.reduce((counter, page) => {
    return counter + page.results.length;
  }, 0);

  if (status === "loading" && !data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (status === "error") {
    return <div>Error: {error.message}</div>;
  }

  if (data === undefined) {
    return <div>Não encontramos nenhum produto para esta data.</div>;
  }

  if (status === "loading" && !data && filter !== null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <InfiniteScroll
        dataLength={dataLength}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              position: "relative",
            }}
          >
            <CircularProgress size={40} thickness={4} />
          </Box>
        }
        endMessage={<Typography variant="overline">{dataLength === 0 ? "Nenhum produto encontrado. Verifique a tabela selecionada." : "Todos os produtos foram carregados."}</Typography>}
      >
        {data.pages.map((page) => (
          <Fragment key={page.pageNumber}>
            {page.results.map((group, groupIndex) => (
              <Box key={`${page.pageNumber}-${groupIndex}`} mt={2}>
                <Typography variant="h5">
                  {new Date(group.semana).toLocaleDateString("pt-BR", {
                    timeZone: "UTC",
                  })}
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: isMediumScreen ? "1fr 1fr" : "1fr",
                    gap: 1,
                    mt: 3,
                  }}
                >
                  {group.results.map((product) => (
                    <ProductCardDefault
                      key={product.id}
                      product={product}
                      grade={product.gradeProduto || []}
                      hover={{
                        isHovered: hoveredProductId === product.id,
                        onHover: handleHover,
                        onHoverLeave: handleHoverLeave,
                      }}
                      select={{
                        onSelectProduct: handleSelectProducts,
                      }}
                      favorite
                      activeHover
                    />
                  ))}
                </Box>
              </Box>
            ))}
          </Fragment>
        ))}
      </InfiniteScroll>
    </Box>
  );
};
