import { IconButton, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";

export const QuantityControl = ({ value, onIncrement, onDecrement, setProductData, productData, id }) => {
  const stock = productData.gradeProduto?.find((item) => item.nro === id)?.estoque;
  const reserved = productData.reservado?.find((item) => item.nro === id)?.estoque || 0;
  const limit = stock - reserved;

  const handleChange = (e) => {
    let value = parseInt(e.target.value);
    const grade = productData.gradePadrao;

    if (isNaN(value) || value < 1) value = ""; 
    if (!value) value = 0;
    if (value < 0) value = 0;
    if (value > limit) value = limit;
    const index = grade.findIndex(item => item.nro === id);
    if (index !== -1) { //atualiza objeto
      grade[index] = { nro: id, estoque: value === 0 ? "" : parseInt(value) };
      return setProductData({ ...productData, gradePadrao: grade })
    }
    //cria novo objeto
    setProductData({ ...productData, gradePadrao: [...grade, { nro: id, estoque: parseInt(value) }] })
  }

  return (
    <Box
      border={1}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius={"5px"}
      borderColor={grey[500]}
      padding={"5px"}
      minWidth={65}
      height={30}
      width="120px"
    >
      <IconButton
        variant="outlined"
        onClick={onDecrement}
        size="small"
        color="primary"
        disabled={!value}
      >
        -
      </IconButton>
      <TextField
        InputProps={{
          sx: {
            "& input": {
              textAlign: "center"
            }
          }
        }}
        sx={{
          '.MuiInputBase-input': {
            border: 'none',
            boxShadow: 'none',
          },
          "fieldset": {
            border: 'none'
          },
          "input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
          },
          "input[type=number]": {
            MozAppearance: "textfield"
          }
        }}
        value={value}
        type="number"
        onChange={(e) => handleChange(e)}
      />
      <IconButton
        variant="outlined"
        onClick={onIncrement}
        size="small"
        color="primary"
        disabled={value === limit}
      >
        +
      </IconButton>
    </Box>
  );
};
