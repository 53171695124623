import { useState, useEffect } from "react";
import { useQuery } from "react-query";

import api from "../../../../../services/api";
import { useTabelasPrecoContext } from "../../../../../contexts/TabelasPreco";
import { useLoggedUser } from "../../../../../contexts/LoggedUserContext";

import { ProductsPDF } from "./pdf/ProductsPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { Download } from "@mui/icons-material";
import { Tooltip, Button } from "@mui/material";

export const ExpressPDF = ({ dates, generating }) => {
  const { loggedUser } = useLoggedUser();
  const { selectedTabelaPreco } = useTabelasPrecoContext();

  const [isPdfReady, setIsPdfReady] = useState(false);

  const document =
    loggedUser.representante || loggedUser.consultor || loggedUser.vendedor;

  const { data: products, isSuccess, isLoading } = useQuery(
    ["pdf-products", dates, generating],
    async ({ signal }) => {
      const response = await api.post(
        `/listaEstoqueRepImed/semana-completo?tabelaPreco=${selectedTabelaPreco.tabelapreco}&tipoUsuario=${loggedUser.tipo}&cnpjRepresentante=${document}&size=1000`,
        { semanas: dates },
        { signal }
      );
      return response.data.records;
    },
    {
      enabled: generating,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setTimeout(() => {
          setIsPdfReady(true);
        }, 1000);
      },
      onError: () => {
        setIsPdfReady(false);
      }
    }
  );

  return (
    <PDFDownloadLink
      document={<ProductsPDF products={products || []} dates={dates} />}
      fileName={`Express-${new Date().toLocaleDateString("pt-BR")}`}
    >
      {({ loading }) => (
        <Tooltip title="Baixar PDF" arrow>
          <Button
            disabled={!isPdfReady || loading}
            color="primary"
            endIcon={<Download />}
            fullWidth
          >
            {!isLoading && !loading && !isPdfReady && "Carregando dados..."}
            {isLoading && "Carregando dados..."}
            {!isLoading && loading && "Gerando PDF..."}
            {!isLoading && !loading && isPdfReady && "Download"}
          </Button>
        </Tooltip>
      )}
    </PDFDownloadLink>
  );
};
