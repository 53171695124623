import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from "@mui/material";

import { QuantityControl } from "../QuantityControl/QuantityControl";
import { useState } from "react";
import { useProductContext } from "../../../../contexts/ProductContext";
import { useEffect } from "react";

export const ProductTable = ({ initialProductData }) => {
  const [productData, setProductData] = useState({
    ...initialProductData,
    gradePadrao: initialProductData.gradePadrao || [],
    gradeProduto: initialProductData.gradeProduto.filter(grade => !!grade.estoque) || [],
  });

  const { setGradeSelecionada } = useProductContext();

  if (!initialProductData.gradeProduto) {
    return;
  }

  useEffect(() => {
    setGradeSelecionada(
      productData.gradePadrao
        .map((grade) => ({
          nro: grade.nro,
          estoque: grade.estoque || "",
        }))
        .sort((a, b) => a.nro - b.nro)
    );
  }, [productData]);

  const gradePadraoEstoque = productData.gradePadrao.reduce((acc, grade) => {
    acc[grade.nro] = grade.estoque;
    return acc;
  }, {});

  const reservedAmount =
    productData.reservado &&
    productData.reservado.reduce((acc, grade) => {
      acc[grade.nro] = grade.estoque;
      return acc;
    }, {});

  const handleIncrement = (gradeNro, stock) => {
    setProductData((prevState) => {
      let updatedGradePadrao = prevState.gradePadrao;
      let itemExists = updatedGradePadrao.some((item) => item.nro === gradeNro);

      if (!itemExists) {
        updatedGradePadrao = [
          ...updatedGradePadrao,
          { nro: gradeNro, estoque: 0 },
        ];
      }

      updatedGradePadrao = updatedGradePadrao.map((item) => {
        const availableStock =
          stock - (reservedAmount ? reservedAmount[gradeNro] || 0 : 0);

        if (item.nro === gradeNro && item.estoque < availableStock) {
          const currentEstoque = gradePadraoEstoque[gradeNro] || 0;
          const updatedEstoque = currentEstoque + 1;
          return { ...item, estoque: updatedEstoque };
        }
        return item;
      });

      return { ...prevState, gradePadrao: updatedGradePadrao };
    });
  };

  const handleDecrement = (gradeNro) => {
    setProductData((prevState) => {
      const updatedGradePadrao = prevState.gradePadrao.map((item) => {
        if (item.nro === gradeNro && item.estoque > 0) {
          const updatedEstoque = item.estoque - 1;
          if (updatedEstoque == 0) return { ...item, estoque: "" };
          return { ...item, estoque: updatedEstoque };
        }
        return item;
      });

      return { ...prevState, gradePadrao: updatedGradePadrao };
    });
  };

  const totalPreOrder =
    productData.gradePadrao &&
    productData.gradePadrao.reduce((acc, grade) => parseInt(acc) + grade.estoque, 0);

  return (
    <TableContainer sx={{ borderRadius: "4px", boxShadow: 1, mt: 1 }}>
      <Table aria-label="Tabela do Produto">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f8f8f8" }}>
            <TableCell align="center">Grade</TableCell>
            <TableCell align="center">Estoque</TableCell>
            <TableCell align="center">Reservado</TableCell>
            <TableCell align="center">Pedido</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productData.gradeProduto.map((item) => (
            <TableRow key={`${item.nro}-${item.estoque}`}>
              <TableCell align="center">{item.nro}</TableCell>
              <TableCell align="center">{item.estoque}</TableCell>
              <TableCell align="center">
                {(reservedAmount && reservedAmount[item.nro]) || 0}
              </TableCell>
              <TableCell sx={{ display: "flex", justifyContent: "center" }} align="center">
                <QuantityControl
                  id={item.nro}
                  productData={productData}
                  setProductData={setProductData}
                  value={gradePadraoEstoque[item.nro]}
                  onIncrement={() => handleIncrement(item.nro, item.estoque)}
                  onDecrement={() => handleDecrement(item.nro)}
                />
              </TableCell>
            </TableRow>
          ))}
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            <Typography color="#000" fontWeight={600}>
              Total: {totalPreOrder}
            </Typography>
          </TableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
