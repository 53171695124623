import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { PedidosItens } from "./PedidosItens";
import { documentMask } from "../../../../utils/documentMask";
import { grey } from "@mui/material/colors";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import { postalCodeFormatter } from "../../../../utils/postalCodeFormatter";

import { formatarData } from "../../../../utils/utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Download } from "@mui/icons-material";
import { InvoicePDF } from "./PDF/Invoice";

export const OrderDetails = ({ orderDetails }) => {
  const [products, setProducts] = useState([]);

  const getProducts = (items) => {
    setProducts(items);
  }

  return (
    <Box p={4}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%"
        }}
      >
        <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
          <Box marginBottom="12px">
            <Typography variant="h5" fontWeight="bold" gutterBottom >
              {`Pedido #${orderDetails.id} - ${orderDetails.clientes.fantasia || orderDetails.clientes.razao}`}
            </Typography>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Typography variant="subtitle2" color={grey[700]}>
                {orderDetails.clientes.razao}
              </Typography>{" "}
              &nbsp; - &nbsp;
              <Typography variant="subtitle2" color={grey[700]}>
                CNPJ: {documentMask(orderDetails.clientes.cnpjCpf)}
              </Typography>
            </Box>
          </Box>
          <PDFDownloadLink
            document={<InvoicePDF order={orderDetails} products={products} />}
            fileName={`${orderDetails.id}-${orderDetails.clientes.razao}-${formatarData(orderDetails.dataFaturamento)}.pdf`}
          >
            {({ blob, url, loading, error }) => (
            <Button variant="contained" disabled={loading}>
              <Typography color="white" fontWeight="bold">GERAR PDF</Typography>
              <IconButton sx={{ color: "#FFF" }}>
                <Download />
              </IconButton>
            </Button>
            )}
          </PDFDownloadLink>
        </Box>
      </Box>

      <Grid container spacing={3} mt={1}>
        {/* Endereço */}
        <Grid style={{ paddingTop: "12px" }} item xs={12} sm={6} md={4} lg={4}>
          <Paper
            sx={{ p: 2, mb: 3, border: "1px solid #ccc", minHeight: "100%" }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Endereço
            </Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary="Rua"
                  secondary={`${orderDetails.clientes.endereco}, Nº ${orderDetails.clientes.numero} - ${orderDetails.clientes.bairro}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Cidade"
                  secondary={`
                    ${orderDetails.clientes.cidade} - ${orderDetails.clientes.uf},
                    ${postalCodeFormatter(String(orderDetails.clientes.cep))}
                  `}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Pagamento */}
        <Grid style={{ paddingTop: "12px" }} item xs={12} sm={6} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              mb: 3,
              border: "1px solid #ccc",
              minHeight: "100%",
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Pagamento
            </Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary="Condição de Pagamento"
                  secondary={orderDetails.condPagamento.descricao}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Resumo */}
        <Grid style={{ paddingTop: "12px" }} item xs={12} sm={12} md={4} lg={4}>
          <Paper
            sx={{ p: 2, mb: 3, border: "1px solid #ccc", minHeight: "100%" }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Resumo
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Subtotal"
                  secondary={currencyFormatter(orderDetails.subtotal || 0)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Desconto"
                  secondary={currencyFormatter(orderDetails.vlrDesconto || 0)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Total"
                  secondary={currencyFormatter(orderDetails.total || 0)}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Items */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, backgroundColor: "#f8f8f8" }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                marginBottom: "16px",
              }}
            >
              Produtos
            </Typography>

            {orderDetails && <PedidosItens sendProducts={getProducts} order={orderDetails} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
