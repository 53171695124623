import { createContext, useContext, useState, useEffect } from "react";

const SelectedProductsContext = createContext();

export const SelectedProductsProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState(new Set());

  useEffect(() => {
    localStorage.setItem(
      "@Express:SelectedProducts",
      JSON.stringify([...selectedProducts])
    );
  }, [selectedProducts]);

  const clearSelectedProducts = () => {
    setSelectedProducts(new Set());
  };

  const toggleSelection = (selectedProduct) => {
    setSelectedProducts((prevSelectedProducts) => {
      let newSelectedProducts = new Set(prevSelectedProducts);
      const validateInsertRemove = [...newSelectedProducts].find((product) => product.id === selectedProduct.id)
      if (!!validateInsertRemove) {
        const removeSelected = [...newSelectedProducts].filter(item => item.id !== selectedProduct.id)
        const setToSend = new Set(removeSelected)
        newSelectedProducts = setToSend;
      } else {
        newSelectedProducts.add(selectedProduct);
      }
      return newSelectedProducts;
    });
  };

  const updateSelectedProductsList = (productsList) => {
    setSelectedProducts(productsList);
  };

  const isSelected = (productId) => {
    return [...selectedProducts].some((product) => product.id === productId);
  };

  return (
    <SelectedProductsContext.Provider
      value={{
        isSelected,
        toggleSelection,
        selectedProducts,
        clearSelectedProducts,
        updateSelectedProductsList,
      }}
    >
      {children}
    </SelectedProductsContext.Provider>
  );
};

export const useSelectedProductsContext = () => {
  return useContext(SelectedProductsContext);
};
