import { useInfiniteQuery } from "react-query";
import api from "../services/api";
import { productWithGrade } from "../utils/productWithGrade";

const PAGE_SIZE = 10;

export async function fetchPriceResponse(tabela, signal) {
  const {
    data: { records: priceResponse },
  } = await api.get(`/vw-tabelas-precos/item?tabelaPreco=${tabela}`, {
    signal
  });

  return priceResponse;
}

export async function fetchResponse(
  tabela,
  pageParam,
  dates,
  filter,
  tipoUsuario,
  signal
) {
  const requestBody = {
    semanas: dates,
    buscar: filter.inputFilter,
  };

  if (filter.selectFilter !== null) {
    requestBody.tipoProduto = [filter.selectFilter];
  }

  const userDocument =
    tipoUsuario.representante || tipoUsuario.consultor || tipoUsuario.vendedor;

  const response = await api.post(
    `/listaEstoqueRepImed/semana?tabelaPreco=${tabela.tabelapreco}&tipoUsuario=${tipoUsuario.tipo}&cnpjReprOrVend=${userDocument}&page=${pageParam}&size=${PAGE_SIZE}`,
    requestBody, {
    signal
  }
  );

  if (response.status !== 200) {
    throw new Error(
      `Problem fetching data: ${response.status} ${response.statusText}`
    );
  }

  return response.data;
}

export async function getData({
  pageParam = 0,
  dates,
  tabela,
  filter,
  tipoUsuario,
  signal
}) {
  const priceResponse = await fetchPriceResponse(tabela.tabelapreco, signal);
  const currentCollection = tabela.tabelapreco.split(" ")[1] ?? "";

  const priceFinalConsumer = await fetchPriceResponse(
    `TABELA_CONS_2_${currentCollection}`
  );

  const responseData = await fetchResponse(
    tabela,
    pageParam,
    dates,
    filter,
    tipoUsuario,
    signal
  );

  const filteredProductPrices = responseData.records.map((product) => {
    const { referencia } = product;
    const precoRepresentante = priceResponse.find(
      (productPrice) => productPrice.produto === referencia
    );

    const precoConsumidor = priceFinalConsumer.find(
      (productPrice) => productPrice.produto === referencia
    );

    return {
      ...product,
      precoRepresentante,
      precoConsumidor,
    };
  });

  const productObjectWithGrade = productWithGrade(filteredProductPrices);

  const groupedProducts = productObjectWithGrade.reduce((acc, product) => {
    const semana = product.semana;
    if (!acc[semana]) {
      acc[semana] = [];
    }
    acc[semana].push(product);
    return acc;
  }, {});

  const groupedData = Object.keys(groupedProducts).map((semana) => ({
    semana,
    results: groupedProducts[semana],
  }));

  const data = {
    results: groupedData,
    next:
      responseData.meta.recordCount > (pageParam + 1) * responseData.meta.limit
        ? pageParam + 1
        : undefined,
  };

  return data;
}

export const useProductsQuery = (dates, tabela, filter, tipoUsuario) => {
  const query = useInfiniteQuery(
    ["expressProducts", dates, tabela, filter.inputFilter, filter.selectFilter],
    ({ pageParam, signal }) =>
      getData({ pageParam, dates, tabela, filter, tipoUsuario, signal }),
    {
      getNextPageParam: (lastPage) => lastPage.next,
      refetchInterval: 100000,
      enabled: !!tabela,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
