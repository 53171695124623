import {
  Box,
  Button,
  Card,
  FormControl,

  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fragment } from "react";
import { documentMask } from "../../../../utils/documentMask";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

export const CustomerCard = ({ item, selectedCustomer, onChangeCustomer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { referencia } = useParams();

  return (
    <Fragment key={item.id}>
      <Box
        component={Card}
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 2,
          border: "1px solid",
          borderColor: selectedCustomer === item ? "#1976d2" : "transparent",
          borderRadius: "8px",
          padding: 2,
          backgroundColor: "#fff",
          boxShadow: 1,
          transition: "box-shadow 0.3s ease",
          cursor: "pointer",
        }}
        onClick={onChangeCustomer}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 2,
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <FormControl>
            <Radio
              value={item.cliente.fantasia}
              checked={selectedCustomer === item}
            />
          </FormControl>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
              width: "100%",
              gap: 2,
              alignItems: "center"
            }}
          >
            <Typography variant="subtitle2" textAlign={isMobile ? "left" : "center"}>
              {item.cliente.fantasia || item.cliente.razao}
            </Typography>

            <Typography
              variant="subtitle2"
              textAlign={isMobile ? "left" : "center"}
              color={grey[600]}
              letterSpacing={0.4}
            >
              {documentMask(item.cliente.cnpjCpf)}
            </Typography>

            <Typography variant="overline" textAlign={isMobile ? "left" : "center"}>
              {item.cliente.endereco}
            </Typography>
          </Box>
        </Box>

        {selectedCustomer === item && (
          <Box
            sx={{
              display: "flex",
              justifyContent: isMobile ? "center" : "flex-end",
              width: "100%",
              mt: 2,
            }}
          >

            <Button
              type="submit"
              variant="contained"
              color="primary"
              component={Link}
              to={referencia ? `/produto/${referencia}` : "/grade/produtos"}
              sx={{ color: "#fff", ":hover": { color: "#fff" } }}
              fullWidth={isMobile}
            >
              Avançar
            </Button>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};
