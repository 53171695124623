import { Box, Button, Link, Typography } from "@mui/material";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { grey } from "@mui/material/colors";

export const EmptyItems = ({ message, children, subtitle }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h1" textAlign={"center"} color={grey[600]}>
          <Typography mb={4} lineHeight={8}>
            {" "}
            <ProductionQuantityLimitsIcon
              sx={{ width: 100, height: 100, color: grey[700] }}
            />
          </Typography>
          {message}
        </Typography>
        {subtitle && (
          <Typography mt={2} variant="h6" fontSize={14}>
            {subtitle}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  );
};
