import api from "../../../../services/api";
import { useQuery } from "react-query";

import { ProductCardDefault } from "../../../../components/GradeExpress/Standard/ProductCardDefault";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import { productObjectPDF } from "../../../../utils/productWithGrade";

export const PedidosItens = ({ order, sendProducts }) => {
  const isSmallScreen = useBreakpoints("sm");

  const fetchOrderItems = async ({ queryKey }) => {
    const [, orderId] = queryKey;
    if (typeof orderId != 'number') return;
    try {
      const response = await api.get(`/checkout/itens/${orderId}`);
      const productWithFormattedGrade = productObjectPDF(
        response.data.records,
        true
      );
      sendProducts(productWithFormattedGrade);
      return productWithFormattedGrade;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: orderItems, isLoading } = useQuery(
    ["orderItems", order.id],
    fetchOrderItems,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Grid container gap={1} justifyContent="space-between">
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        orderItems &&
        orderItems.length > 0 &&
        orderItems.map((item) => (
          <Box width={isSmallScreen ? "98%" : "49%"} xs={1} key={item.id}>
            <ProductCardDefault
              disableImageCache
              disableApplyGradeButton
              disableLight
              grade={item.gradeProduto}
              product={item}
              finalPrice={item.unitario}
            />
          </Box>
        ))
      )}
    </Grid>
  );
};
