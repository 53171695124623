import {
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ProductCardDefault } from "../../../../components/GradeExpress/Standard/ProductCardDefault";
import EditIcon from "@mui/icons-material/Edit";
import { useProductContext } from "../../../../contexts/ProductContext";
import { memo } from "react";
import { useCallback } from "react";
import { useState } from "react";

import { DialogGrade } from "../../Checkout/Components/DialogGrade";

import { currencyFormatter } from "../../../../utils/currencyFormatter";
import { blue, cyan, green } from "@mui/material/colors";
import { toast } from "react-toastify";
import { isMultipleOfBaseMultiples } from "../../../../utils/isMultipleOfBaseMultiples";
import { Delete, Person2, Sell } from "@mui/icons-material";
import { RemoveItemModal } from "./RemoveItemModal";
import { useSelectedProductsContext } from "../../../../contexts/SelectedProductsContext";

export const ProductList = memo(
  ({
    product,

    refreshStorage,
    selectedProducts,
    applyGradeOnClick,
    isSelectedGrade,
  }) => {
    const { produtoSelecionado, selecionarProduto, gradeSelecionada } =
      useProductContext();

    const { updateSelectedProductsList } = useSelectedProductsContext();

    const formattedDate = new Date(product.semana).toLocaleDateString("pt-BR", {
      timeZone: "UTC",
    });

    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const isSelected =
      selectedProducts &&
      Array.from(selectedProducts).find((p) => p.id === product.id) !==
      undefined;

    const handleOpenModal = (product) => {
      selecionarProduto(product);

      setModalIsOpen(!modalIsOpen);
    };

    const handleCloseModal = () => {
      setModalIsOpen(false);
    };

    const handleConfirm = useCallback(() => {
      const totalQuantityInGrade = gradeSelecionada.reduce((acc, grade) => parseInt(acc) + grade.estoque, 0);

      const isNumberMultiple = isMultipleOfBaseMultiples(totalQuantityInGrade);

      if (!isNumberMultiple && product.linha !== "MOCHILA") {
        return toast.error(
          "A quantidade total da grade deve ser múltipla de 8, 10, 12, 15 ou 18."
        );
      }

      const sessionProducts = sessionStorage.getItem(
        "@Express:SessionProducts"
      );
      const parsedSessionProducts = JSON.parse(sessionProducts) || [];

      const updatedSessionProducts = parsedSessionProducts.map(
        (sessionProduct) => {
          if (sessionProduct.id === produtoSelecionado.id) {
            if (produtoSelecionado)
              return {
                ...sessionProduct,
                gradePadrao: gradeSelecionada.filter(item => !!item.estoque),
              };
          }
          return sessionProduct;
        }
      );

      sessionStorage.setItem(
        "@Express:SessionProducts",
        JSON.stringify(updatedSessionProducts)
      );

      refreshStorage();

      setModalIsOpen(false);
    }, [gradeSelecionada, produtoSelecionado, setModalIsOpen]);

    const handleRemoveGrade = useCallback((productToRemoveGrade) => {
      const updatedProduct = { ...productToRemoveGrade, gradePadrao: [] };

      const sessionProducts = sessionStorage.getItem(
        "@Express:SessionProducts"
      );
      let parsedSessionProducts = JSON.parse(sessionProducts) || [];
      parsedSessionProducts = parsedSessionProducts.map((p) =>
        p.id === updatedProduct.id ? updatedProduct : p
      );

      sessionStorage.setItem(
        "@Express:SessionProducts",
        JSON.stringify(parsedSessionProducts)
      );

      refreshStorage();
    });

    const totalItemOrdered = product.gradePadrao.reduce(
      (acc, grade) => acc + grade.estoque,
      0
    );

    const handleRemoveModal = () => {
      setIsRemoveModalOpen(!isRemoveModalOpen);
    };

    const onRemoveProduct = (productToRemove) => {
      try {
        const sessionProducts = sessionStorage.getItem(
          "@Express:SessionProducts"
        );
        const parsedSessionProducts = JSON.parse(sessionProducts) || [];

        const updatedSessionProducts = parsedSessionProducts.filter(
          (p) => p.id !== productToRemove.id
        );

        sessionStorage.setItem(
          "@Express:SessionProducts",
          JSON.stringify(updatedSessionProducts)
        );

        updateSelectedProductsList(updatedSessionProducts);

        refreshStorage();

        toast.success("Produto removido com sucesso");
      } catch (error) {
        toast.error("Erro ao remover o produto. Por favor, tente novamente.");
        console.error("Error removing product:", error);
      } finally {
        setIsRemoveModalOpen(false);
      }
    };

    return (
      <>
        <Box
          my={2}
          sx={{
            outline: isSelected ? `3px solid blue` : "none",
            borderRadius: 1,
            zIndex: 2,
          }}
        >
          <ProductCardDefault
            product={product}
            grade={product.gradePadrao.filter(grade => !!grade.estoque) || []}
            variant="extended"
            disableApplyGradeButton={isSelectedGrade}
            actions={{
              handleAddGrade: () => applyGradeOnClick(product),
              remove: () => handleRemoveGrade(product),
            }}
            totalQuant={totalItemOrdered || "0"}
            customRemoveRender={
              isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  ml={1}
                  onClick={handleRemoveModal}
                >
                  <Delete sx={{ color: "error.main" }} />
                </Box>
              )
            }
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: isMobile ? "flex-start" : "flex-end",
                px: 1,
              }}
            >
              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    my: 1,
                    mb: isMobile && 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleRemoveModal}
                  >
                    <Delete
                      sx={{
                        color: "error.main",
                        ":hover": {
                          opacity: "0.9",
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Button
                color={product.gradePadrao.length > 0 ? "warning" : "info"}
                variant="outlined"
                size="small"
                fullWidth={isMobile}
                sx={{ minWidth: !isMobile && 230, my: isMobile && 2 }}
                startIcon={<EditIcon />}
                onClick={(event) => {
                  event.stopPropagation();
                  handleOpenModal(product);
                }}
              >
                {product.gradePadrao.length > 0 ? "Editar" : "Adicionar"}
              </Button>
            </Box>
          </ProductCardDefault>
        </Box>

        {modalIsOpen && (
          <DialogGrade
            key={`${modalIsOpen}-edit-grade`}
            modal={{ modalIsOpen, handleCloseModal, handleConfirm }}
            product={produtoSelecionado}
          />
        )}

        {isRemoveModalOpen && (
          <RemoveItemModal
            open={isRemoveModalOpen}
            onClose={handleRemoveModal}
            onConfirm={() => onRemoveProduct(product)}
          />
        )}
      </>
    );
  }
);
