import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import LogoMin from "../../src/assets/logo_democrata_sembox_express.png";
import {
  AppBar,
  Badge,
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  Favorite,
  Menu as MenuIcon,
  Person,
  Storefront,
} from "@mui/icons-material";

import ChecklistIcon from "@mui/icons-material/Checklist";

import { blue, grey, orange, red } from "@mui/material/colors";
import { Cart } from "./Cart";
import { logout } from "../services/auth";
import { ConfigTabela } from "./ConfigTabela";
import { toast } from "react-toastify";
import { useTabelasPrecoContext } from "../contexts/TabelasPreco";
import { useSelectedProductsContext } from "../contexts/SelectedProductsContext";

const Header = ({ enableModalOnStart, ...props }) => {
  const { tabelasPrecoRepresentante, selectedTabelaPreco, dispatch } = useTabelasPrecoContext();
  const { clearSelectedProducts } = useSelectedProductsContext();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(enableModalOnStart || false);
  const [selectModalIsOpen, setSelectModalIsOpen] = useState(enableModalOnStart || false);

  const loggedUser = JSON.parse(localStorage.getItem("@Express:User"));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const handleBurgerMenuClick = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleMenuItemClick = (path) => {
    props.history.push(path);
    handleDrawerClose();
  };

  const handleChangeTabelaPreco = (event) => {
    dispatch({
      type: "SET_SELECTED_TABELA_PRECO",
      payload: event.target.value,
    });

    toast.success("Tabela de preço alterada com sucesso!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

    setSelectModalIsOpen(false);

    clearSelectedProducts();
  };

  const tabelasPrecoVer = tabelasPrecoRepresentante.filter(
    (item) =>
      item.tabelapreco.includes("VER") && item.tabelapreco.startsWith("BR")
  );

  const tabelasPrecoInverno = tabelasPrecoRepresentante.filter(
    (item) =>
      item.tabelapreco.includes("INV") && item.tabelapreco.startsWith("BR")
  );

  const pagePath = props.location.pathname === "/grade/produtos";

  return (
    <>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: grey[900] }} component="nav">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: grey[900],
            p: 2,
          }}
          className="container-xl"
        >
          <Badge
            badgeContent="BETA"
            color="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <img src={LogoMin} alt="Logo Democrata" style={{ maxWidth: 150 }} />
          </Badge>

          {isMobile ? (
            <>
              <Box display="flex" alignItems="center">
                <Button
                  variant="text"
                  sx={{ color: "#fff" }}
                  onClick={() => handleMenuItemClick("/checkout")}
                >
                  <Cart />
                </Button>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ mx: 2, bgcolor: grey[100] }}
                />

                <IconButton
                  sx={{
                    color: grey[100],
                    "&:hover": {
                      color: orange[300],
                    },
                  }}
                  onClick={handleBurgerMenuClick}
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={handleDrawerClose}
                sx={{ color: grey[800] }}
              >
                <Box
                  sx={{
                    padding: 2,
                    backgroundColor: !isMobile && grey[800],
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <List sx={{ cursor: "pointer" }}>
                    {loggedUser?.privilegios?.subnvlPrivilegio === 3 && (
                      <ListItem
                        onClick={() => handleMenuItemClick("/usuarios")}
                        sx={{ color: !isMobile && grey[100], my: 1 }}
                      >
                        <ListItemIcon>
                          <Person />
                        </ListItemIcon>
                        <ListItemText primary="Usuários" />
                      </ListItem>
                    )}
                    <ListItem
                      onClick={() => handleMenuItemClick("/lista/produtos")}
                      sx={{ color: !isMobile && grey[100], my: 1 }}
                    >
                      <ListItemIcon>
                        <Storefront />
                      </ListItemIcon>
                      <ListItemText primary="Produtos" />
                    </ListItem>
                    <ListItem
                      onClick={() => handleMenuItemClick("/favoritos")}
                      sx={{ color: !isMobile && grey[100], my: 1 }}
                    >
                      <ListItemIcon>
                        <Favorite />
                      </ListItemIcon>
                      <ListItemText primary="Favoritos" />
                    </ListItem>
                    <ListItem
                      onClick={() => handleMenuItemClick("/pedidos-realizados")}
                      sx={{ color: !isMobile && grey[100], my: 1 }}
                    >
                      <ListItemIcon>
                        <ChecklistIcon />
                      </ListItemIcon>
                      <ListItemText primary="Pedidos Realizados" />
                    </ListItem>
                    <Divider
                      sx={{ width: "100%", my: 2, bgcolor: grey[100] }}
                    />

                    {!pagePath && (
                      <ConfigTabela
                        key={`${modalIsOpen}-mobile`}
                        modalIsOpen={modalIsOpen}
                        handleDrawerClose={handleDrawerClose}
                        handleOpenModal={handleOpenModal}
                      />
                    )}
                  </List>
                </Box>
                <Box sx={{ mx: 2 }}>
                  <Button
                    color="error"
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{ color: !isMobile && grey[100], my: 2 }}
                    onClick={() => {
                      logout();
                      handleMenuItemClick("/");
                    }}
                  >
                    Sair
                  </Button>
                </Box>
              </Drawer>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {loggedUser?.privilegios?.subnvlPrivilegio === 3 && (
                <Button
                  variant="text"
                  sx={{
                    color: grey[100],
                    "&:hover": {
                      color: orange[300],
                    },
                  }}
                  onClick={() => handleMenuItemClick("/usuarios")}
                >
                  Usuarios
                </Button>
              )}

              <Button
                variant="text"
                sx={{
                  color: grey[100],
                  "&:hover": {
                    color: orange[300],
                  },
                }}
                onClick={() => handleMenuItemClick("/lista/produtos")}
              >
                Produtos
              </Button>

              <Button
                variant="text"
                sx={{
                  color: grey[100],
                  "&:hover": {
                    color: orange[300],
                  },
                }}
                onClick={() => handleMenuItemClick("/favoritos")}
              >
                Favoritos
              </Button>

              <Button
                variant="text"
                sx={{
                  color: grey[100],
                  "&:hover": {
                    color: orange[300],
                  },
                }}
                onClick={() => handleMenuItemClick("/pedidos-realizados")}
              >
                Pedidos Realizados
              </Button>

              <IconButton
                sx={{
                  color: grey[100],
                  "&:hover": {
                    color: orange[300],
                  },
                }}
                onClick={() => handleMenuItemClick("/checkout")}
              >
                <Cart />
              </IconButton>

              <Divider
                orientation="vertical"
                flexItem
                sx={{ mx: 2, bgcolor: grey[100] }}
              />

              {!pagePath && (
                <ConfigTabela
                  key={`${modalIsOpen}-mobile`}
                  modalIsOpen={modalIsOpen}
                  handleOpenModal={handleOpenModal}
                />
              )}

              <Button
                sx={{ ml: 2, "&hover": { backgroundColor: red[600] } }}
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  logout();
                  handleMenuItemClick("/");
                }}
              >
                Sair
              </Button>
            </Box>
          )}
        </Box>
      </AppBar>
      {modalIsOpen && (
        <Dialog open={modalIsOpen} onClose={handleOpenModal} fullWidth>
          <DialogTitle>Configurações - Representante</DialogTitle>
          <DialogContent sx={{ overflow: "hidden" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                alignItems: "center",
                gap: 2,
                my: 2,
                width: "100%",
              }}
            >
              <FormControl sx={{ mr: !isMobile && 4 }} fullWidth>
                <InputLabel id="choose-tabela-preco-con">
                  Tabela de Preco
                </InputLabel>
                <Select
                  open={selectModalIsOpen}
                  onClose={() => setSelectModalIsOpen(false)}
                  onOpen={() => setSelectModalIsOpen(true)}
                  labelId="choose-tabela-preco-con"
                  label="Tabela de Preço"
                  value={selectedTabelaPreco || ""}
                  onChange={handleChangeTabelaPreco}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: "calc(100% - 96px)",
                      },
                    },
                  }}
                >
                  <ListSubheader
                    sx={{
                      backgroundColor: orange[400],
                      fontWeight: 600,
                      color: "#fff",
                    }}
                  >
                    Verão
                  </ListSubheader>
                  {tabelasPrecoVer.map((item) => (
                    <MenuItem value={item} key={`${item.tabelapreco}`}>
                      {item.tabelapreco}
                    </MenuItem>
                  ))}

                  {tabelasPrecoVer.length === 0 && (
                    <MenuItem>
                      Não existem tabelas de verão disponiveis
                    </MenuItem>
                  )}
                  <ListSubheader
                    sx={{
                      backgroundColor: blue[400],
                      fontWeight: 600,
                      color: "#fff",
                    }}
                  >
                    Inverno
                  </ListSubheader>
                  {tabelasPrecoInverno.map((item) => (
                    <MenuItem value={item} key={`${item.tabelapreco}`}>
                      {item.tabelapreco}
                    </MenuItem>
                  ))}

                  {tabelasPrecoInverno.length === 0 && (
                    <MenuItem>
                      Não existem tabelas de verão disponiveis
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenModal} variant="contained">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withRouter(Header);
