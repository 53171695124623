import { Box, Card as CardMui, Grid, IconButton, Paper, Typography } from '@mui/material';
import Image from 'mui-image';
import styled from 'styled-components';
import { blue, cyan, green, grey } from '@mui/material/colors';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const breakpoint = (breaksize, style) => {
  const sm = '600px';
  const md = '900px';
  let negatedMedia = [];
  const mediaStyle = style.map(css => {
    if (css[0] === '!') {
      negatedMedia = [...negatedMedia, css.substring(1)];
      return `${css.split(':')[0].substring(1)}: initial`;
    }
    return css;
  })

  const styleString = mediaStyle.join(';');
  const negatedString = negatedMedia.join(';');

  const finalString = `
    ${negatedString};

    @media (max-width: ${breaksize === 'md' ? md : sm}) {
      ${styleString};
    }
  `;
  return finalString;
}

export const Card = styled(CardMui)`
  width: 100%;
  height: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: row;
  border-style: solid;
  z-index: 10;
  &:hover: {
    backgroundColor: #FCFCFC;
  }

  ${breakpoint('md', ['flex-direction: column'])}
`;

export const BoxContainerContent = styled(Box)`
  flex: 3 1 0%;
  align-items: center;
  display: flex;
  flex-direction: row;

  ${breakpoint('md', ['flex-direction: column'])}
`;

export const BoxContainerImage = styled(Box)`
  width: 100px;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  background-color: #f8f8f8;
  overflow: hidden;

  ${breakpoint('md', [
  'margin-bottom: 5px',
  'width: 100%',
  'min-height: 150px',
  'max-height: 150px',
  'height: 150px'
])}
`;

export const BoxImage = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`;

export const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 60px;
`;

export const StyledImage = styled(Image).attrs({
  fit: 'contain'
})`
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
  cursor: pointer;
  background-color: #faf5f5;
  &:hover {
    background-color: #fee9be;
  }
`;

export const BoxContent = styled(Box).attrs({
  px: 1,
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${breakpoint('md', ['margin-top: 16px'])}
`;

export const BoxContainerTitle = styled(Box).attrs({
  mb: 1
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BoxContentTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const BoxTitle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const TextTitle = styled(Typography).attrs({
  variant: 'h6'
})`
  
  ${breakpoint('md', [
  'font-size: 16px',
  '!white-space: nowrap',
  '!overflow: hidden',
  '!text-overflow: ellipsis',
  '!max-width: 234px'
])}
`;

export const TextReference = styled(Typography).attrs({
  variant: 'body1',
  color: 'textSecondary'
})`
  font-weight: bold;

  ${breakpoint('sm', ['font-size: 15px'])}
`;

export const BoxContainerPrice = styled(Box).attrs({
  mb: 1,
})`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
`;

export const BoxContentPrice = styled(Box).attrs({
  mb: 1,
})`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BoxPriceSug = styled(Box).attrs({
  px: 1,
})`
  background-color: ${blue[50]};
  border-radius: 10px;
  border: 1px solid ${blue[100]};
  display: flex;
  align-items: center;
  width: 100%;
`;

export const TextPriceSug = styled(Typography)`
  color: ${blue[800]};
  font-size: 16px;
  text-align: center;
`;

export const TextPriceFinal = styled(Typography)`
  font-weight: 600;
  color: ${grey[800]};
  text-align: center;

  ${breakpoint('md', ['!font-size: 18px'])}
`;

export const BoxPriceRec = styled(Box).attrs({
  px: 1,
  ml: 1,
})`
  background-color: ${green[50]};
  border-radius: 10px;
  border: 1px solid ${green[100]};
  display: flex;
  align-items: center;
`;

export const TextPriceRec = styled(Typography).attrs({
  variant: 'body2'
})`
  font-size: 16px;
  text-align: center;
  color: ${cyan[900]};
`;

export const TextDate = styled(Typography).attrs({
  mb: 1
})``;

export const BoxContainerGrade = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const GridGrade = styled(Grid).attrs({
  container: true
})`
  min-height: 64px;
  align-items: flex-end;
`;

export const BoxActionGrade = styled(Box)`
  position: sticky;
`;

export const BoxTotal = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextTotal = styled(Typography).attrs({
  variant: 'overline',
  mx: 1
})``;

export const IconButtonCheck = styled(IconButton)`
  transition: transform 0.3s ease-in-out;
  position: sticky;
  &:hover {
    transform: scale(1.1);
  }
`;

export const CheckCircleIconMui = styled(CheckCircleIcon)`
  width: 24px;
  height: 24px;
`;

export const BoxContainerAction = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  ${breakpoint('md', ['flex-direction: column'])}
`;

export const BoxDialog = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
`;

export const TextDialogTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  color: ${grey[800]};
  text-align: center;
`;

export const TextDialogRef = styled(Typography).attrs({
  mt: 2,
})`
  color: ${grey[800]};
  text-align: center;
`;

export const PaperDialog = styled(Paper)`
  margin-top: 32px;
  padding: 32px;
  height: 400px;
`;