import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { documentMask } from "../../../../utils/documentMask";

export const CardOrder = ({ order, openModal }) => {

  const orderStatus = {
    S: { status: "Processado", color: "success" },
    N: { status: "Não Processado", color: "error" },
  };

  return (
    <Card sx={{ minHeight: 180 }}>
      <CardContent>
        <Box
          sx={{
            minHeight: 100,
            maxHeight: 100,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              sx={{
                fontSize: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: 150,
              }}
              variant="h5"
              color={grey[800]}
            >
              {order.fantasia || order.razaoCliente}
            </Typography>
            <Typography
              sx={{ fontSize: 14, maxWidth: "60%" }}
              variant="subtitle2"
              color={grey[800]}
            >
              {order.razaoCliente || order.fantasia}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color={grey[600]}>
              CNPJ: {documentMask(order.clientes.cnpjCpf)}
            </Typography>
          </Box>
          <Chip
            label={orderStatus[order.processado].status}
            color={orderStatus[order.processado].color}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{order.pedidoOriginal}</Typography>
          <Tooltip title="Data de Faturamento" arrow>
            <Typography variant="subtitle1" color={grey[700]}>
              {new Date(order.dataFaturamento).toLocaleDateString("pt-BR", {
                timeZone: "UTC",
              })}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button onClick={openModal} size="small">
            Visualizar Itens
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};
