import { Box, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import { memo } from "react";

export const Grade = memo(({ nro, estoque, alert }) => {
  const GradeBox = styled(Box)`
    display: flex;
    height: 100%;
    flex-direction: column;
    margin-right: 4px;
    align-items: center;
  `;

  const GradeItem = styled(Box)`
    min-width: 31px;
    min-height: 28px;
    padding: 2px;
    margin: 2px;
    border-radius: 4px;
    text-align: center;
    user-select: none;
    width: 100%;
  `;

  return (
    <GradeBox>
      <GradeItem
        item
        bgcolor={alert ? "#C70039" : "#e8e8e8"}
        color={alert ? "#FFF" : grey[800]}
        fontWeight={500}
      >
        {nro}
      </GradeItem>
      <GradeItem item bgcolor="#f8f8f8">
        {estoque !== 0 && estoque}
      </GradeItem>
    </GradeBox>
  );
});

export const GradeItemComponent = ({ nro }) => {
  const GradeItem = styled(Box)`
    min-width: 31px;
    font-weight: 700;
    color: #565656;
    padding: 2px;
    border-radius: 4px;
    text-align: center;
    user-select: none;
  `;

  return <GradeItem bgcolor="#f8f8f8">{nro}</GradeItem>;
};
